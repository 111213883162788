export enum SOCKET_EVENTS {
  INITIAL_STATIONS_DATA = "INITIAL_STATIONS_DATA",
  STATIONS_UPDATE = "STATIONS_UPDATE",
  FATAL_ERROR = "FATAL_ERROR",
}

export enum PLAYER_STATE {
  PAUSED,
  PLAYING,
  LOADING,
}
