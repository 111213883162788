const frames = [
  `${process.env.PUBLIC_URL}/jamming/1.gif`,
  `${process.env.PUBLIC_URL}/jamming/2.gif`,
  `${process.env.PUBLIC_URL}/jamming/3.gif`,
  `${process.env.PUBLIC_URL}/jamming/4.gif`,
  `${process.env.PUBLIC_URL}/jamming/5.gif`,
  `${process.env.PUBLIC_URL}/jamming/6.gif`,
  `${process.env.PUBLIC_URL}/jamming/7.gif`,
  `${process.env.PUBLIC_URL}/jamming/8.gif`,
  `${process.env.PUBLIC_URL}/jamming/9.gif`,
  `${process.env.PUBLIC_URL}/jamming/10.gif`,
  `${process.env.PUBLIC_URL}/jamming/11.gif`,
  `${process.env.PUBLIC_URL}/jamming/12.gif`,
  `${process.env.PUBLIC_URL}/jamming/13.gif`,
  `${process.env.PUBLIC_URL}/jamming/14.gif`,
  `${process.env.PUBLIC_URL}/jamming/15.gif`,
  `${process.env.PUBLIC_URL}/jamming/16.gif`,
];

export default frames;
